<template>
  <e-sidebar
    :title="isEdit ? $t('Editar Grupo de Aprovação') : $t('Novo Grupo de Aprovação')"
    :show="showSidebar"
    :fetching="fetching"
    :saving="saving"
    width="600px"
    @save="saveApprovalGroup"
    @hidden="hideSidebar"
  >
    <template #content>
      <FormulateForm
        ref="approvalGroupForm"
        name="approvalGroupForm"
      >
        <b-row>
          <b-col md="12">
            <FormulateInput
              id="approval-group-name"
              v-model="localApprovalGroup.name"
              name="name"
              type="text"
              :label="$t('Nome')"
              class="required"
              validation="required"
            />
          </b-col>

          <b-col md="12">
            <FormulateInput
              id="approval-group-description"
              v-model="localApprovalGroup.description"
              name="description"
              type="textarea"
              rows="2"
              :label="$t('Descrição')"
            />
          </b-col>

          <b-col md="12">
            <e-user-search
              ref="addUserInput"
              v-model="localMemberId"
              :label="$t('Adicionar usuário')"
              :hide-id-or-document-list="getActiveMemberUserIds"
              @input="(val, objSelected) => onAddMember(objSelected)"
            />
          </b-col>
        </b-row>

        <b-card-actions
          action-collapse
          :title="$t('Membros do grupo')"
        >
          <b-row>
            <b-col md="12">
              <FormulateInput
                id="approval-group-filter_name"
                v-model="localFilter.name"
                name="filterName"
                type="text"
                :label="$t('Filtrar usuário')"
              />
            </b-col>

            <b-col md="12">
              <b-table
                ref="members-table"
                show-empty
                responsive
                class="bordered"
                :fields="fields"
                :items="getFilteredMembers"
                :empty-text="$t('APPROVAL_GROUP.UI.EMPTY_MEMBERS_TABLE')"
              >
                <template #cell(action)="row">
                  <e-grid-actions
                    :show-update="false"
                    :show-delete="true"
                    @delete="onDeleteMember(row.item)"
                  />
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card-actions>
      </FormulateForm>
    </template>
  </e-sidebar>
</template>

<script>
import { BRow, BCol, BTable } from 'bootstrap-vue'
import { ESidebar } from '@/views/components'
import { mapActions } from 'vuex'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import { localListAddItem, localListDeleteItem } from '@/store/utils'
import EUserSearch from '@/views/components/inputs/EUserSearch.vue'

const getInitialLocalMember = () => ({
  id: null,
  user: { id: null, name: '' },
  isDeleted: false,
})

const getInitialLocalApprovalGroup = () => ({
  id: null,
  name: '',
  description: '',
  members: [],
})
const getInitialLocalFilter = () => ({
  name: '',
})

export default {
  components: {
    ESidebar,
    BRow,
    BCol,
    BTable,
    BCardActions,
    EGridActions,
    EUserSearch,
  },

  mixins: [],

  props: {},

  data() {
    return {
      showSidebar: false,
      fetching: false,
      saving: false,
      localMemberId: null,
      localApprovalGroup: getInitialLocalApprovalGroup(),
      localFilter: getInitialLocalFilter(),
    }
  },

  computed: {
    getActiveMembers() {
      return this.localApprovalGroup?.members?.filter(m => !m.isDeleted) || []
    },

    getFilteredMembers() {
      if (this.localFilter.name) {
        return (
          this.getActiveMembers?.filter(m =>
            m.user?.name?.toUpperCase().includes(this.localFilter.name.toUpperCase())
          ) || []
        )
      }

      return this.getActiveMembers
    },

    getActiveMemberUserIds() {
      return this.getActiveMembers.map(m => ({ id: m.user.id }))
    },

    isEdit() {
      return !!this.localApprovalGroup.id
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Nome'),
          key: 'user',
          thClass: 'text-center',
          tdClass: 'text-left',
          formatter: (value, index, item) => {
            let result = item.user?.name || ''
            if (item.user?.userName) result += ` (${item.user?.userName})`
            return result
          },
        },
      ]
    },
  },

  watch: {},

  methods: {
    ...mapActions('pages/catalog/products', { comboFetchDomainData: 'fetchDomainData' }),

    async show(id) {
      if (!id) {
        this.cleanSidebar()
        this.showSidebar = true
      } else {
        this.showSidebar = true

        try {
          this.fetching = true
          const { data } = await this.$http.get(`/api/approval-groups/${id}`)

          this.localApprovalGroup = data
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      }
    },
    hideSidebar() {
      this.cleanSidebar()
      this.showSidebar = false
      this.$formulate.resetValidation('approvalGroupForm')
    },
    cleanSidebar() {
      this.localMemberId = null
      this.localApprovalGroup = getInitialLocalApprovalGroup()
    },

    async saveApprovalGroup() {
      this.$refs.approvalGroupForm.showErrors()
      if (this.$refs.approvalGroupForm.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      try {
        this.saving = true
        const { id, name, description, members } = this.localApprovalGroup
        let membersToSave = []

        if (this.isEdit) {
          membersToSave = members?.map(m => ({
            id: m.id,
            userId: m.user.id,
            isDeleted: m.isDeleted,
          }))
        } else {
          membersToSave = members?.map(m => m.user.id)
        }

        const body = {
          id,
          name,
          description,
          members: membersToSave || [],
        }
        const result = await this.$http({
          url: '/api/approval-groups',
          method: this.isEdit ? 'PUT' : 'POST',
          data: body,
        })

        this.showSuccess({
          message: this.$t(`${this.isEdit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
        })
        this.showSidebar = false
        this.$emit('after-save', result.data)
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    onAddMember(user) {
      if (!user) return

      if (this.getActiveMembers.some(m => m.user.id === user.id)) {
        this.showWarning({ message: this.$t('Usuário já faz parte deste grupo.') })
        this.cleanAddMemberInput()
        return
      }

      this.localApprovalGroup.members = localListAddItem(
        this.localApprovalGroup.members,
        {
          ...getInitialLocalMember(),
          user: { ...user },
        },
        true
      )

      this.cleanAddMemberInput()
    },

    onDeleteMember(item) {
      this.localApprovalGroup.members = localListDeleteItem(
        this.localApprovalGroup.members,
        item.id || item.localId
      )
    },

    cleanAddMemberInput() {
      if (this.$refs.addUserInput) {
        this.localMemberId = null
        this.$refs.addUserInput.cleanInput()
      }
    },
  },
}
</script>

<style></style>
