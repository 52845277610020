var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    attrs: {
      title: _vm.isEdit
        ? _vm.$t("Editar Grupo de Aprovação")
        : _vm.$t("Novo Grupo de Aprovação"),
      show: _vm.showSidebar,
      fetching: _vm.fetching,
      saving: _vm.saving,
      width: "600px",
    },
    on: { save: _vm.saveApprovalGroup, hidden: _vm.hideSidebar },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "FormulateForm",
              {
                ref: "approvalGroupForm",
                attrs: { name: "approvalGroupForm" },
              },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "approval-group-name",
                            name: "name",
                            type: "text",
                            label: _vm.$t("Nome"),
                            validation: "required",
                          },
                          model: {
                            value: _vm.localApprovalGroup.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.localApprovalGroup, "name", $$v)
                            },
                            expression: "localApprovalGroup.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "approval-group-description",
                            name: "description",
                            type: "textarea",
                            rows: "2",
                            label: _vm.$t("Descrição"),
                          },
                          model: {
                            value: _vm.localApprovalGroup.description,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.localApprovalGroup,
                                "description",
                                $$v
                              )
                            },
                            expression: "localApprovalGroup.description",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("e-user-search", {
                          ref: "addUserInput",
                          attrs: {
                            label: _vm.$t("Adicionar usuário"),
                            "hide-id-or-document-list":
                              _vm.getActiveMemberUserIds,
                          },
                          on: {
                            input: function (val, objSelected) {
                              return _vm.onAddMember(objSelected)
                            },
                          },
                          model: {
                            value: _vm.localMemberId,
                            callback: function ($$v) {
                              _vm.localMemberId = $$v
                            },
                            expression: "localMemberId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card-actions",
                  {
                    attrs: {
                      "action-collapse": "",
                      title: _vm.$t("Membros do grupo"),
                    },
                  },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "approval-group-filter_name",
                                name: "filterName",
                                type: "text",
                                label: _vm.$t("Filtrar usuário"),
                              },
                              model: {
                                value: _vm.localFilter.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localFilter, "name", $$v)
                                },
                                expression: "localFilter.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c("b-table", {
                              ref: "members-table",
                              staticClass: "bordered",
                              attrs: {
                                "show-empty": "",
                                responsive: "",
                                fields: _vm.fields,
                                items: _vm.getFilteredMembers,
                                "empty-text": _vm.$t(
                                  "APPROVAL_GROUP.UI.EMPTY_MEMBERS_TABLE"
                                ),
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "cell(action)",
                                  fn: function (row) {
                                    return [
                                      _c("e-grid-actions", {
                                        attrs: {
                                          "show-update": false,
                                          "show-delete": true,
                                        },
                                        on: {
                                          delete: function ($event) {
                                            return _vm.onDeleteMember(row.item)
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }