<template>
  <section>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="3">
            <FormulateInput
              id="approval_group-name"
              v-model="filters.name"
              name="approvalGroupId"
              :label="$t('Nome')"
              type="text"
            />
          </b-col>
          <b-col md="4">
            <e-user-search
              id="approval_group-user_id"
              v-model="filters.userId"
              :label="$t('Usuário')"
              name="approval_group-user_id"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="getData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        id="approval-group-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('Approval Group'))"
        :fields="fields"
        :items="approvalGroups"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(action)="{ item }">
          <e-grid-actions
            :show-update="$can('Update', 'ApprovalGroup')"
            :show-delete="$can('Delete', 'ApprovalGroup')"
            @update="onUpdateApprovalGroup(item)"
            @delte="onDeleteApprovalGroup(item)"
          />
        </template>
      </b-table>
      <b-row class="pt-1">
        <b-col>
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col>
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="approval-group-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <approval-group-sidebar
      ref="approvalGroupSidebar"
      @after-save="getData"
    />

    <fab
      v-if="$can('Create', 'ApprovalGroup')"
      :main-tooltip="$t('Adicionar Approval Group')"
      @click="onCreateApprovalGroup"
    />
  </section>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary, EFilters, EGridActions } from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { statusTypes } from '@/mixins'
import { mapActions, mapState } from 'vuex'
import EUserSearch from '@/views/components/inputs/EUserSearch.vue'
import ApprovalGroupSidebar from './components/ApprovalGroupSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EFilters,
    EGridActions,
    ApprovalGroupSidebar,
    EUserSearch,
  },

  mixins: [statusTypes],

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapState('pages/security/approvalGroup', ['approvalGroups', 'paging', 'sorting', 'filters']),

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Id'),
          key: 'id',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '80px' },
          sortable: true,
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          thClass: 'text-center',
          thStyle: { width: '200px' },
          tdClass: 'text-left',
        },
        {
          label: this.$t('Descrição'),
          key: 'description',
          thClass: 'text-center',
          tdClass: 'text-left',
          formatter: value => value || '-',
        },
      ]
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/security/approvalGroup', [
      'fetchApprovalGroups',
      'resetFilters',
      'setCurrentPage',
      'setPageSize',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchApprovalGroups()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },
    resetFiltersLocal() {
      this.resetFilters()
      this.setCurrentPage(1)
      this.getData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    onCreateApprovalGroup() {
      this.$refs.approvalGroupSidebar.show()
    },
    onUpdateApprovalGroup(item) {
      this.$refs.approvalGroupSidebar.show(item?.id)
    },
    onDeleteApprovalGroup(item) {
      console.log('delete', item)
    },
  },
}
</script>
